import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import ChooseUs from '../components/ChooseUs'
import Layout from '../components/Layout'
import Order from '../components/Order'
import SEO from '../components/SEO'
import config from '../utils/siteConfig'

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${props => props.theme.colors.white};
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1120px;
  padding: 24px;
  margin: auto;
`

const Header = styled.div`
  margin: 32px 0;
  display: flex;
`

const Title = styled.h1`
  font-size: 2em;
  font-weight: 700;
  line-height: 1.6;
  color: #222;
`

const Description = styled.div`
  font-size: 1.5em;
  color: #555;
`

const PartnerTemplate = ({ data, pageContext }) => {
  const { title, description, logo } = data.contentfulPartner
  const { langKey, slug } = pageContext

  return (
    <Layout langKey={langKey}>
      <SEO title={`${title} - ${config.siteTitle}`} pagePath={slug} />
      <Wrapper>
        <Container>
          <Header>
            <GatsbyImage image={logo.gatsbyImageData} alt={title} />
            <div style={{ marginLeft: 12 }}>
              <Title>{title}</Title>
              <Description>{description}</Description>
            </div>
          </Header>
        </Container>
      </Wrapper>

      <ChooseUs langKey={langKey} />
      <Order
        langKey={langKey}
        backgroundColor={props => props.theme.colors.darkGray}
        inputColor={props => props.theme.colors.gray}
      />
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    contentfulPartner(id: { eq: $id }) {
      title
      description
      link
      logo {
        gatsbyImageData(cropFocus: CENTER, height: 80, width: 80)
      }
    }
  }
`

export default PartnerTemplate
